import React from "react"
import PropTypes from 'prop-types';

import Layout from "components/layout.js"
import { StaticQuery, graphql, Link } from "gatsby"
import SEO from "components/seo.js"
import { MenuPanels } from "components/content/menu"

// import React from 'react';
// import { graphql } from 'gatsby';
// import Layout from 'components/layout.js';



// import { Link } from 'gatsby';

// import TableReactable from 'reactable';
// import 'reactable/lib/styles.css';

import ResponsiveEmbed from 'react-responsive-embed';
import JSONData from '../../../content/people.json';
import SliderJSONData from '../../../content/homeSlider.json';
import SliderTweetsJSONData from '../../../content/tweets.json';
import Helmet from 'react-helmet';
// import { Timeline as TimelineScribble, Event } from 'react-timeline-scribble';
// import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import {
  Button,
  Container,
  Carousel,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavDropdown,
  Form,
  FormControl,
  FormGroup,
  ButtonToolbar,
} from 'react-bootstrap';
// import { Network, Node, Edge } from 'react-vis-network';

// import { OffCanvas, OffCanvasMenu, OffCanvasBody } from 'react-offcanvas';
// import { Route, NavLink, HashRouter } from 'react-router-dom';
// import SweetAlert from 'react-bootstrap-sweetalert';
// import { FontAwesomeIcon } from 'react-fontawesome';
// import Jumbotron from 'react-bootstrap/Jumbotron';


const WhyPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query WhyPageQuery {
        site {
          siteMetadata {
            title   
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="Why?" />

          <div className={"page-header"}>
            <h2>Why {process.env.REACT_APP_NAME}?</h2>
          </div>

          <div className={"bg-color1"}>
            <div className={"container"}>
              <div className={"integration-guid2"}>
                <div className={"title  text-center"}>
                  <br /> <br />
                  <h2 className="text-center text-primary">
                    There are trillions of reasons why...
                  </h2>
                </div>

                <div className={"content"}>
                  <div className="container">
                    <div className="page-header2">
                      <h3 className="text-center text-danger">
                        Trillions in economic losses...
                      </h3>
                      <h5 className="text-center text-danger">
                        $2T in economic losses this year in 2020 with estimated
                        $8T by 2022
                      </h5>
                      <br />
                      <h3 className="text-center text-danger">
                        Billions in regulatory fines...
                      </h3>
                      <h5 className="text-center text-danger">
                        $5B in FTC fines paid by Facebook for Cambridge
                        Analytica privacy crisis in 2019
                      </h5>
                      <br /> <br />
                    </div>
                    <div className="page-header2">
                      <h3 className="text-center text-default">
                        People care about trusted, quality technology along with
                        privacy &amp; security &amp; other benefits like AI more
                        than ever...
                      </h3>
                      {/* <h5 className="text-center text-danger">$5B in FTC fines paid by Facebook for Cambridge Analytica privacy crisis in 2019</h5> */}
                      <br /> <br />
                    </div>
                    <div className="row grid-divider">
                      <div className="col-sm-4">
                        <div className="col-padding">
                          <h4 className=" ">
                            Everyone relies on tech &amp; the Internet.
                          </h4>
                          <p>
                            We all depend on the Internet more than ever
                            especially after COVID-19 &amp; coronavirus &amp;
                            work from home.
                          </p>
                          <p>
                            <b>
                              We risk more people not using tech &amp; the
                              Internet for commerce due to many risks, leaving
                              dollars on the table.
                            </b>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="col-padding">
                          <h4 className="  ">Cyber threats are on the rise.</h4>
                          <p>
                            Ransomware, spyware, identity theft, financial fraud
                            plus data misuse, cyber attacks and data breaches
                            are on the rise.
                          </p>
                          <p>
                            <b>
                              Organizations that collect data must build trust
                              with customers, comply with standards, be more
                              resilient &amp; maintain cyber risk insurance.
                            </b>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="col-padding">
                          <h4 className="">In Transparency We Must Trust.</h4>
                          <p>
                            People and Business Customers often blindly trust
                            tech companies to safeguard their privacy and data.
                          </p>
                          <p>
                            <b>
                              Customers consent to long policies &amp; user
                              agreements without truly knowing if the associated
                              company can protect their data.
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row grid-divider">
                    {" "}
                    <div className="col-sm-12">
                      <br />
                      <h3 className="text-center  pt-4 pb-3">Our vision</h3>
                      <h4 className="text-center  ">
                        Help improve quality, trust and value of the tech
                        industry beginning with our customers.
                      </h4>

                      {/* <p className="text-center">
              Bad actors need to be forced to come “into the light”.
            </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"bg-color1  "}>
            <div className="row d-none">
              <div className="col-sm-12 pt-4 pb-4">
                <MenuPanels />
              </div>
            </div>
          </div>

          <div className={"bg-color1  bg-dark text-white d-none"}>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="tab-content " id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-me"
                      role="tabpanel"
                      aria-labelledby="pills-me-tab"
                    >
                      <br />
                      <h4 className="text-center pt-5 pb-4">
                        You need to know which products, sites and apps are not
                        trustable with privacy, personal data, cybersecurity or
                        not aligned with your needs.
                      </h4>

                      {/* {process.env.NODE_ENV=="development"  ? ( */}
                      <>
                        {/* <img alt="" src="/images/dpt-problems.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
              <img alt="" src="/images/dpt-privacy-policy-problems.svg" />
              <img alt="" src="/images/dpt-privacy-policy-problems-stats.svg" />
              <img alt="" src="/images/dpt-privacy-iot-problems-stats.svg" />
              <img alt="" src="/images/dpt-privacy-review-problem.svg" />
              <img alt="" src="/images/dpt-privacy-policy-changes-problems.svg" />
              <img alt="" src="/images/dpt-privacy-review-problem-stats.svg" />
              <img alt="" src="/images/dpt-privacy-review-education.svg" />
              <img alt="" src="/images/dpt-fragmented-data.svg" />
              <img alt="" src="/images/dpt-spheres.svg" />
              <img alt="" src="/images/dpt-fragmented-privacy.svg" />
              <img alt="" src="/images/dpt-fragmented-privacy-broken.svg" />
              <img alt="" src="/images/consumer-id-theft.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/dpt-trust-problems.svg" /> 
              <img alt="" src="/images/consumer-fcc.svg" />
              <img alt="" src="/images/consumer-privacy-crisis.svg" />
              <img alt="" src="/images/consumer-change.svg" />
              <img alt="" src="/images/dpt-changes-needed.svg" />
              <img alt="" src="/images/dpt-solution.svg" />
              <img alt="" src="/images/dpt-how-we-help.svg" />
              <img alt="" src="/images/dpt-why-it-matters.svg" />
              <img alt="" src="/images/dpt-people-centered-values.svg" /> */}
                      </>
                      {/* ) : (
      <>  
      </>
    )} */}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-customers"
                      role="tabpanel"
                      aria-labelledby="pills-customers-tab"
                    >
                      <h4 className="text-center pt-5 pb-4">
                        Organizations and businesses need to know which
                        products, sites and apps are not trustable with privacy,
                        personal data, cybersecurity or not aligned with their
                        customer's needs.
                      </h4>

                      {/* {process.env.NODE_ENV=="development"  ? ( */}
                      <>
                        {/* <img alt="" src="/images/b2b-threats.svg" />
              <img alt="" src="/images/b2b-third-party-risk.svg" />
              <img alt="" src="/images/b2b-third-party-risk-costly.svg" />
              <img alt="" src="/images/b2b-third-party-risk-nth-parties.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
              <img alt="" src="/images/dpt-privacy-policy-changes-problems.svg" />
              <img alt="" src="/images/dpt-privacy-review-problem-stats.svg" />
              <img alt="" src="/images/dpt-changes-needed.svg" />
              <img alt="" src="/images/dpt-vision.svg" />
              <img alt="" src="/images/b2b-why.svg" />
              <img alt="" src="/images/b2b-whynow.svg" />
              <img alt="" src="/images/dpt-why-it-matters.svg" /> */}
                      </>
                      {/* // ) : (
    //   <>  
    //   </>
    // )}
     */}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-business"
                      role="tabpanel"
                      aria-labelledby="pills-business-tab"
                    >
                      <h4 className="text-center pt-5 pb-4">
                        Service Providers and Data Brokers need to show they are
                        trustable with privacy &amp; personal data &amp;
                        resilient with cybersecurity.
                      </h4>

                      {/* {process.env.NODE_ENV=="development"   ? ( */}
                      <>
                        {/* <img alt="" src="/images/dpt-trust-problems.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
              <img alt="" src="/images/b2b-third-party-risk.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/consumer-fcc.svg" />
              <img alt="" src="/images/dpt-vision.svg" />
              <img alt="" src="/images/b2b-why.svg" />
              <img alt="" src="/images/b2b-whynow.svg" />
              <img alt="" src="/images/dpt-why-it-matters.svg" /> */}
                      </>
                      {/* ) : (
      <>  
      </>
    )} */}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-partners"
                      role="tabpanel"
                      aria-labelledby="pills-partners-tab"
                    >
                      <h4 className="text-center pt-5 pb-4">
                        Our partners are working to build better ecosystems for
                        their customers and end users as well as build a better,
                        smarter and safer Internet for everyone.
                      </h4>

                      <h5 className="text-center pt-4 pb-4 text-white">
                        UVision is excited to help partner organizations
                        everywhere
                        <br />
                        we can help your platform or enterprise be efficient
                        with digital protection to be more trusted with
                        customers.
                      </h5>

                      {/* {process.env.NODE_ENV=="development"  ? ( */}
                      <>
                        {/*         
              <img alt="" src="/images/dpt-trust-problems.svg" />
              <img alt="" src="/images/dpt-provider-privacy-problem.svg" />
 
              <img alt="" src="/images/b2b-third-party-risk.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats1.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats2.svg" />
              <img alt="" src="/images/consumer-ransomware-problems-stats3.svg" />
              <img alt="" src="/images/dpt-vision.svg" />
              <img alt="" src="/images/b2b-why.svg" />
              <img alt="" src="/images/b2b-whynow.svg" /> */}
                      </>
                      {/* ) : (
      <>  
      </>
    )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="call-to-action">
            <div className="container">
              <div className="call-to-action__content">
                <h2 className="text-white">Let's talk</h2>
                <p>
                  What are your biggest problems? Do you have questions about
                  possible solutions? We'd like to help.
                </p>
              </div>
              <div className="button">
                <a href="/lets-talk/">Let's talk</a>
              </div>
            </div>
          </div>
          {/* 
    <div className={"call-to-action"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target={"_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div> */}
          {/* <Why/> */}
        </Layout>
      </>
    )}
  />
)

export default WhyPage

var title = process.env.REACT_APP_NAME; //TODO - consolidate app names for simple brand config global override
var appName = title; 
// import {
//   Button,
//   Container,
//   Carousel,
//   Table,
//   Row,
//   Col,
//   Nav,
//   NavItem,
//   Navbar,
//   NavbarBrand,
//   NavDropdown,
//   Form,
//   FormControl,
//   FormGroup,
//   ButtonToolbar,
// } from 'react-bootstrap';

// import { Demo as Annotation} from 'components/annotations';

const site = {};

site.title = process.env.REACT_APP_NAME;


export const Why = () => (
  <div>
    {/* <Layout> */}
      {/* <div className="jumbotron">
        <div className="container">
          <h1 className="display-32">Why DataPolicyTrust?</h1>
        </div>
      </div> */}


      <section className="header header-2" style={{ minHeight: '30vh' }}>

        <div className="container  ">
          <div className="row">
            <div className="col-sm-12 text-center  transparent">
              <Carousel
                className="hidden-xs-block"
                style={{ minHeight: '50vh' }}
              >
                {' '}
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">A problem we all face</h1>
                  <br />
                  <br />
                  <p className="text-white">
                    According to “Harris 2018 ID Theft Survey”: Over 50% of
                    consumers have experienced a data breach. 2 In 2017, over
                    140 million hours were spent by identity theft victims
                    trying to solve their issue. 1 There was a victim of
                    identity theft every 2 seconds in 2017. 2 Nearly 15 billion
                    dollars were stolen from identity theft victims in 2017. 1
                    {/* 1 – Based on an online survey of 540 U.S. adults who experienced ID theft in 2017, conducted for Symantec by the Harris Poll, January 2018.
2 – Based on an online survey of 5,389 U.S. adults conducted for Symantec by The Harris Poll, January 2018
https://www.lifelockbusinesssolutions.com/industries/lifelock-breach-response-services/ */}
                  </p>
                </Carousel.Item>{' '}
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">A problem we all face</h1>
                  <br />
                  <p className="text-white">
                    <br /> <br />
                    By 2022, cybersecurity ratings will be as important as
                    credit ratings when assessing the risk of existing & new
                    business relationships plus impact cyber insurance.
                    (Gartner, 2019) - According to Gartner, “These scores will
                    have an impact on cost/availability of cyber insurance. By
                    proactively assessing risk appetite and the value of the
                    desired business outcome, CIOs and chief information
                    security officers (CISOs) can transform digital risk
                    management into a competitive advantage.” -
                    https://www.gartner.com/en/information-technology/security-risk-management-leaders
                    Roughly 1M tech jobs remain unfilled with the roles most in
                    demand: research scientists, systems managers, analysts,
                    engineers and software architects. <br /> <br />
                    In addition, nearly 80% of all organizations & vendors are
                    failing their IT, privacy and security compliance checks or
                    having breaches often due to human error. <br /> <br />
                  </p>
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">Trillions in economic losses.</h1>
                  <br />
                  <h3 className="text-white">
                    In 2019, cybercrime economic loss projections estimated at
                    $2T, by 2022 estimated to be $8T & affect businesses &
                    organizations of all sizes.
                    {/* * Source - https://cybertechaccord.org/ - Losses are cumulative over five year, 2017 – 2022. James Moar; Juniper Research: The Future of Cybercrime & Security: Enterprise Threats & Mitigation 2017-2022 (April 25, 2017); https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation */}
                  </h3>
                  <p className="text-white">
                    {' '}
                    <a href="https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation">
                      1.* Losses are cumulative over five year, 2017 – 2022.
                      James Moar; Juniper Research: The Future of Cybercrime &
                      Security: Enterprise Threats & Mitigation 2017-2022 (April
                      25, 2017);{' '}
                    </a>
                  </p>
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">
                    Cyber attacks, identity theft & financial fraud is on the
                    rise.
                  </h1>

                  <p className="text-white">
                    {/* Cyber attacks, identity theft & financial fraud is on the rise. 
                  <br />
                  <br />
Trillions in economic losses. */}
                    <br />
                    <br />
                    We risk more people not using tech & the Internet for
                    commerce due to privacy risks, leaving dollars on the table.
                    <br />
                    <br />
                    <a href="https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation">
                      1.* Losses are cumulative over five year, 2017 – 2022.
                      James Moar; Juniper Research: The Future of Cybercrime &
                      Security: Enterprise Threats & Mitigation 2017-2022 (April
                      25, 2017);{' '}
                    </a>
                  </p>
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">
                    Cyberattacks cause small businesses to shutter
                  </h1>
                  <br />
                  <br />

                  <p className="text-white">
                    {' '}
                    Cyberattacks have caused small businesses to shutter their
                    doors, hospitals to delay surgeries & governments to halt
                    services, among other disruptions & safety risks.
                    <a href="https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation">
                      1.* Losses are cumulative over five year, 2017 – 2022.
                      James Moar; Juniper Research: The Future of Cybercrime &
                      Security: Enterprise Threats & Mitigation 2017-2022 (April
                      25, 2017);{' '}
                    </a>
                  </p>
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">
                    Cyberattacks projected to cause $8T in economic losses by
                    2022 (Juniper, 2017)
                  </h1>
                  <br />
                  <br />

                  <p className="text-white">
                    {/* 1.* Losses are cumulative over five year, 2017 – 2022. James Moar; Juniper Research: The Future of Cybercrime & Security: Enterprise Threats & Mitigation 2017-2022 (April 25, 2017); 
                  
                  https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation */}

                    <a href="https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation">
                      1.* Losses are cumulative over five year, 2017 – 2022.
                      James Moar; Juniper Research: The Future of Cybercrime &
                      Security: Enterprise Threats & Mitigation 2017-2022 (April
                      25, 2017);{' '}
                    </a>
                  </p>
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">
                    Third party risk is too high & too important
                  </h1>
                  <br />
                  <br />
                  80% of organizations believe vetting third parties for
                  cyber-risk is critical only 60% organizations admit they're
                  only somewhat or not at all effective in vetting third parties
                  for cyber-risk
                  {/* Third-Party Cyber-Risk by the Numbers
Recent stats show that the state of third-party cyber risk and vendor risk management remains largely immature at most organizations.
https://www.darkreading.com/risk/third-party-cyber-risk-by-the-numbers/d/d-id/1334443  */}
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">
                    By 2022, cybersecurity ratings will be as important as
                    credit ratings when assessing risks of existing & new
                    business relationships. (Gartner, 2019)
                  </h1>
                  <br />
                  <br />
                  <a href="https://www.gartner.com/en/information-technology/security-risk-management-leaders">
                    {' '}
                    Gartner
                  </a>
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">
                    Cybersecurity ratings will also impact the cost and
                    availability of cyber insurance. (Gartner, 2019)
                  </h1>
                  <br />
                  <br />
                  <a href="https://www.gartner.com/en/information-technology/security-risk-management-leaders">
                    {' '}
                    Gartner
                  </a>
                </Carousel.Item>
                <Carousel.Item style={{ minHeight: '50vh' }}>
                  <h1 className="text-white">
                    By proactively assessing risk appetite and the value of the
                    desired business outcome, CIOs and chief information
                    security officers (CISOs) can transform digital risk
                    management into a competitive advantage. (Gartner, 2019)
                  </h1>
                  <br />
                  <br />
                  <a href="https://www.gartner.com/en/information-technology/security-risk-management-leaders">
                    {' '}
                    Gartner
                  </a>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section
        className="features features-1 d-none"

        // style={{ backgroundColor: '#0e65f0' }}
      >
        <div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="text-white2">
                How much of your personal data is exposed?
              </h1>
              {/* 
                  <h5>
                    SIGN UP TO GET NOTIFIED ABOUT OUR UPCOMING BETA! Get access
                    to useful reports on the technologies you use like this one
                    on Twitter!
                  </h5> */}
              {/* <div className="col-xs-12 text-center">
                    <div className="text-center">
                      <div className="container">
                        <div className="row text-center">
                          <div className="col col-xs-2">&nbsp;</div>

                          <div className="col col-xs-2">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
            </div>
          </div>

          {/* <div className="row"> */}
          {/* <div className="col-sm-12 text-center"> */}
          {/* <div className="local-video-container"> */}
          {/* <div className="background-image-holder"> */}
          {/* <img
                      alt="Background Image"
                      className="background-image"
                      src="/theme/img/hero4.jpg"
                    /> */}
          {/* </div> */}
          {/* <video controls="">
                    <source src="/theme/video/video1.webm" type="video/webm" />
                    <source src="/theme/video/video1.mp4" type="video/mp4" />
                    <source src="/theme/video/video1.ogv" type="video/ogg" />
                  </video> */}
          {/* <div className="play-button" /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          <div className="row">
            <div className="col-sm-12 text-center">
              <form className="contained-form form-email" method="post">
                <h6 className="text-white2">Sign up now!</h6>
                {/* <input
                  type="text"
                  name="name"
                  className="signup-name-field validate-required"
                  placeholder="Your Name"
                /> */}
                <input
                  type="text"
                  name="email"
                  className="validate-required validate-email signup-email-field"
                  placeholder="Email Address"
                />
                <input
                  type="submit"
                  value="Sign up for beta »"
                  className="btn btn-primary"
                />
                <p className="sub">
                  * We don’t share your personal info with anyone. Check out our{' '}
                  <a href="/privacy/"> Privacy Policy</a> for more information.
                </p>
                <div className="form-error">
                  <span>Please fill all fields correctly</span>
                </div>

                <div className="form-success">
                  <span>Thanks, your details were sent</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <a id="content" className="in-page-link" />

      <section className="features features-3 d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
              <h3>
                Learn how your personal data is used with each app or website
                you use.
              </h3>
              <p>
                We help you save time by pinpointing where data privacy and
                personal privacy terms are in the privacy policies.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 text-center feature">
              <i className="icon-brush-paint-streamline" />
              <h5>See answers to questions related to data privacy.</h5>
              <p>
                You will learn how your personal privacy parameters relate to
                the app or service's privacy policies.
              </p>
            </div>

            <div className="col-sm-4 text-center feature">
              <i className="icon-browser-streamline-window" />
              <h5>Privacy For The Modern Web, IoT, AI, XR &amp; more</h5>
              <p>
                Based on the most modern web standards, devices, platforms,
                operating systems and eco systems, you will be able to get more
                insights into how providers use data over time.
              </p>
            </div>

            <div className="col-sm-4 text-center feature">
              <i className="icon-keyboard" />
              <h5>Personal Privacy Freedom</h5>
              <p>
                We provide you the most useful and helpful data and information
                on each app, site and provider to help you evaluate personal and
                data privacy risks of the technologies you depend on.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="features features-3  d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
              <h3>Why It Matters</h3>
              {/* <p>
										We help you save time by pinpointing where data privacy and personal privacy
										terms are in the privacy policies.
									</p>
								  */}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4 text-center feature">
              <i className="icon-brush-paint-streamline" />
              <h5>Privacy Matters</h5>
              <p>
                People, families and customers need a better, smarter & more
                efficient way to review & evaluate a technology providers data
                policies, disclosures, practices, and reputation, for many
                different sites, services, products & applications to monitor
                data privacy risks for the user & owners of the data.
              </p>
            </div>
            <div className="col-sm-4 text-center feature">
              <i className="icon-brush-paint-streamline" />
              <h5>Your personal data should be private</h5>
              <p>
                Not everything online about you is created by you. Your social
                media profiles contains the information you control. But what
                matters most is your private information — including home
                address, phone number and family member's names — available to
                anyone on public websites that you know nothing about.
              </p>
            </div>

            <div className="col-sm-4 text-center feature">
              <i className="icon-browser-streamline-window" />
              <h5>Your personal data affects your life</h5>
              <p>
                <br />
                This data affects your life. It tells friends, colleagues, even
                strangers, stalkers and thieves where and how you live your
                life. Because the information exposed about you online affects
                your life offline.
              </p>
            </div>

            <div className="col-sm-4 text-center feature">
              <i className="icon-keyboard" />
              <h5>
                You should always know what, why, when, where, &amp; how your
                data is used
              </h5>
              <p>
                You need to control where your private information is exposed
                and who's accessing it to effectively protect and monitor your
                reputation, privacy, and identity. We'll show you everything an
                app or service is doing with your data — and help users monitor
                the privacy and cyber security health of the service.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 text-center">
              <hr />
              <h3>How It Works</h3>
              {/* {!-- <h5>Many past students have gone on to work in high-profile web companies.</h5> --} */}

              <h4>We scan the internet for you</h4>
              <p>
                You'll see all the data privacy and data policy information
                about each service you care about that's potentially exposing
                your data and shared or sold across the web. We even give you
                the ability to keep up to date with a single click.
              </p>
              <h4>We help you assess your personal data risk</h4>
              <p>
                Define what matters to you out of the parameters we are
                analyzing for you. Get risk scores for each parameter that you
                care about.
              </p>
              <h4>
                We monitor the privacy and cyber security policies, practices
                and track record of the apps and services you use 24/7
              </h4>
              <p>
                Get alerts when we find new information about the apps or
                services you care about. You'll also be alerted when an app or
                service profile updates: opportunities of interest, or threats
                to your privacy, reputation and identity.
              </p>
              {/* <hr /> */}
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2  "
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white">The Problem</h2>
              <br />
              <h5 className="text-white">
                {/* <h3></h3> */}
                {/* <p> */}
                “It’s kind of crazy today that we live quite comfortably in a
                world that George Orwell would have thought is super crazy. Like
                we all carry a phone with a microphone that could be turned on
                really at any time without our knowledge, with a GPS that knows
                our position, and a camera and, well, kind of all of our
                personal information.” - Elon Musk <br />
                <br />
                <a href="https://www.csoonline.com/article/3208035/elon-musk-s-top-cybersecurity-concern-preventing-a-fleet-wide-hack-of-teslas.html">
                  Elon Musk’s top cybersecurity concern: Preventing a fleet-wide
                  hack of Teslas
                </a>
                {/* </p> */}
              </h5>
            </div>
          </div>
      <div className="jumbotronb"> 
          <img
            alt="" 
            src="/img/dpt-privacy-spheres.svg"
          />
        </div>
        </div>
      </section>
      <div className="page-header"  style={{ backgroundColor: 'white' }}> 
      <section
        className="features features-2 jumbotron"
        style={{ backgroundColor: 'white' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white2">Cyber resilience is a big problem</h2>
              <br />    <a href="https://www.helpnetsecurity.com/2020/02/04/cyber-resilient/?hss_channel=tw-64677310">
                  What makes some organizations more cyber resilient than
                  others?
                </a>   <br />
                <br />
              <h5 className="text-white2">
                “The sizable number of vendor relationships that most
                organizations have poses a significant challenge to their
                ability to monitor that business ecosystem. Yet, given the large
                percentage of breaches that originate in an organization’s
                supply chain, companies need to ensure that their cyber defenses
                stretch beyond their own walls.”
             
            
              </h5>
              <p>
                {' '}
                <h4 className="text-white2">
                  Though the scope of a privacy program goes beyond
                  cybersecurity, there is a substantial overlap between the two
                  worlds. 
                  </h4>
                  <h4 className="text-white2">
                  Business and IT leaders can strengthen the case for improved
                  security measure if it addresses cybersecurity as well as
                  privacy risks.
                </h4>
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
      <section
        className="features features-2 jumbotron"
        style={{ backgroundColor: 'white' }} 
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white2">
                Data Theft and Protection is a big problem
              </h2>

              <h5 className="text-white2">
                47% of cloud databases are currently unencrypted
                <br />
                <br />
                60% of cloud storage services have logging disabled
                <br />
                <br />
                It takes 1 misconfiguration to compromise an entire cloud
                environment <br />
                <br />
                Cybercrime groups are stealing cloud resources for cryptojacking
                <br />
                <br />
              </h5>
              {/* <a href="https://www.helpnetsecurity.com/2020/02/07/cloud-databases-unencrypted/
">43% of cloud databases are currently unencrypted</a> */}
              <p>
                {' '}
                <h4 className="text-white2">
                  The case for strong data privacy, strong system-wide
                  cryptography and the corresponding brand values and policies
                  from companies we trust with our information – like Apple, and
                  not like Facebook – has never been stronger.
                </h4>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2 jumbotron"
        style={{ backgroundColor: 'white' }} 
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white2">
                @FTC & @EFF thinks transparency is key...
              </h2>
              <br />
              <h5 className="text-white2">
                “.@cmcsherr w/ @EFF says transparency is job #1. You can’t have
                transparency without accountability. But we need a window into
                company practices, the data ecosystem and the risks to the
                data.”
                <br />
                <br />
                <a href="https://twitter.com/FTC/status/1116034587069702145">
                  @FTC 04/12/2019
                </a>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2 jumbotron"
        style={{ backgroundColor: 'white' }} 
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white2">
                @FTC thinks privacy policies are important...
              </h2>
              <br />
              <h5 className="text-white2">
                “Privacy policies are criticized as long and confusing. There’s
                some truth to that but they make companies study their data
                practices and put privacy processes in place. While consumers
                don’t read them, many others do & can put pressure on companies
                to improve practices.” @FTC 04/12/2019
                https://twitter.com/FTC/status/1116037361727606785
                <br />
                <br />
                <a href="https://twitter.com/FTC/status/1116034587069702145">
                  @FTC 04/12/2019
                </a>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-3 jumbotron" 
        style={{ backgroundColor: '#4a86e8' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white">Simplifying data privacy for all.</h2>
              <br />
              <h5 className="text-white">
                Businesses, providers and other organizations that process &amp;
                broker data need to build trust with their audiences - consumers
                and customers.
                <br />
                <br />
                Our solution will help improve privacy risks with all
                organizations for increased value and commerce through trust.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-3 jumbotron" 
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
              <h3 className="text-white">The Solution </h3>
              <p className="lead text-white">
                The first global privacy platform for people and industry to
                search, analyze & monitor the policies, trust, privacy, security
                & safety of services that collect data -- every site, app,
                product & service in the world. A trusted platform for useful
                data : risk scores, policy analytics, alerts, and data on
                disclosures, practices & track record.
              </p>
              <p className="lead text-white">
                “A new type of ‘trust score’ & ‘privacy rating’ will be even
                more important & we should make changes happen faster to benefit
                of all consumers & producers on the Internet with a trusted
                platform with analyzed policies, parameters, risks & historical
                track record of each service.” - Omar Uddin, Founder &amp; CEO
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="features features-2"
        style={{ backgroundColor: 'maroon' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white">Why Now?</h2>
              <br />
              <h5 className="text-white">
                Consumers, customers and regulators need a simple and scalable
                solution to continuously monitor organizations': <br /> <br />
                data policy disclosures <br />
                data security practices <br />
                responsiveness to data security risks <br />
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2"
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white">
                {' '}
                Why should businesses and industry care?
              </h2>
              <br />
              <h5 className="text-white">
                Organizations will earn increased revenues and trust from
                individuals to protect their identities, money, families and
                lives.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2"
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white">
                {' '}
                Why do our strategic partners care?
              </h2>
              <br />
              <h5 className="text-white">
                Several platform companies and an IT Organization want to
                consider to partner, integrate or license our API as they see
                immense value on top of what businesses will see. They see their
                customer’s and user’s experience becoming more trusted with our
                data & rating system and links back to our site.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2"
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white"> Value Proposition</h2>
              <br />
              <h5 className="text-white">
                DataPolicyTrust™ will save time, money & reduce loss, risk,
                waste, fraud, theft & confusion for people & business & fosters
                more trust between consumers & providers to build a better,
                smarter & safer Internet.
                {/* 


After all, given all the data that companies and devices are collecting about us, isn't it time that we have a trusted eye monitoring privacy standards to make sure our data is safe guarded? 
DPT will benefit all organizations seeking to improve privacy & security.   */}
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2"
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white"> The Difference</h2>
              <br />
              <h5 className="text-white">
                DPT saves consumers time and money while businesses can improve
                conversion rates & brand trust by demonstrating their commitment
                to open and transparent data collection practices. We aim to
                protect user’s data everywhere : we will never expose it. Unlike
                traditional revenue models based on online PII harvesting, or
                invasive ad-tracking, DPT will collect revenue from premium
                users & providers.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2"
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white"> Differentiators & Advantages</h2>
              <br />
              <h5 className="text-white">
                The world’s first platform to increase confidence & trust
                between consumers & providers, fostering a “People-Centered
                Internet”. <br />
                <br />
                Powered by machine intelligence continually sifting through
                billions of haystacks for hundreds of “needles” as in risky
                signals relying initially on AI machine learning of policies,
                agreements, disclosures & other data. <br />
                <br />
                Improves via humans & data since tech & policies are for humans
                after all. <br />
                <br />
                Continuously monitors & enables a trusted ledger of data on
                services & also enables providers to disclose more data as
                needed.
                <br />
                <br />
                Provides all Internet stakeholders simplified data policy
                reports, privacy histories, ratings and risk scores for products
                & services.
                <br />
                <br />
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section
        className="features features-2"
        style={{ backgroundColor: 'black' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <br />
              <br />
              <h2 className="text-white"> Past Alternatives</h2>
              <br />
              <h5 className="text-white">
                The average adult requires 30 minutes to review policy
                disclosures presented during registration -- nearly 75% do not
                review disclosures at all while the remainder spend less than a
                minute. Businesses pay staff or lawyers (most people cannot
                afford it & even some lawyers skip it). None serve as an
                effective service or method to help people save time to evaluate
                & monitor data collecting solutions & organizations.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <div className="container marketing">
        {/* <!-- Three columns of text below the carousel --> */}
        <div className="row">
          <div className="col-md-12">
            <div className=" mx-auto " style2="width: 1000px; height: 75px;">
              <br />
              <br />
              <h1
                className="text-black font-weight-normal text-center"
                id="typing-animation"
              >
                Our prime directive is to help people and organizations
                worldwide gain more...
                {/* <br /> <br />
                <span className="text-primary">
                  <span className="u-text-animation u-text-animation--typing" />
                </span> */}
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* <!-- Main jumbotron for a primary marketing message or call to action --> */}

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <h2 className=" text-center">Confidence</h2> <br />
            <br />
            <h2 className=" text-center">Trust</h2> <br />
            <br />
            <h2 className=" text-center">Safety</h2> <br />
            <br />
            <h1 className=" text-center">
              People struggle with personal data exposure.
            </h1>{' '}
            <br /> <br />
            <h2>
              This is a growing problem we all face together, connected or not.
            </h2>{' '}
            <br /> <br />
            <p>
              Our world and lives have benefited from increase in technology but
              also had some negative impacts due to increase in survellience,
              sensors, data collection and AI powered processing across our
              world, in our vehicles, in our homes, in our offices and
              workplaces, in our pockets, in our bodies and with adoption and
              dependence throughout our lives. There is an ever growing threat
              surface area with the Internet and the constant risk, threat and
              damage of massive data breaches leaving consumers stressed out and
              left to deal with the data breach impact, loss and stress during
              identity theft or worse events occur such as extortion, financial
              loss, cyber stalking, bullying or other more serious or dangerous
              attacks.
              {/* Fragmentation in data, devices, software
            leading to frustration, time wasted &amp; loss opportunities for
            billions of people that use Apps, AI, Chatbots, software, OS,
            Devices and IoT. */}
            </p>
            <p />
            <h2>The world of apps &amp; privacy is fragmented </h2>
            <p>
              Our world is growing of people, devices, apps &amp; data in a
              growing, fragmented digital world of sites &amp; services.
            </p>
            <p>
              This can lead to data leaks &amp; loss of user confidence,
              meanwhile consumers are demanding more privacy &amp; controls.
            </p>
            <p>
              It’s creating a data “deluge” for users &amp; information silos
              while also leading to increased data privacy &amp; security risks
              &amp; issues.
            </p>
            <h2>Identity Theft Victims on the Rise</h2>
            <p>
              According to “Harris 2018 ID Theft Survey” conducted by The Harris
              Poll for Symantec Corporation:
            </p>
            <ul>
              <li>Over 50% of consumers have experienced a data breach. 7</li>
              <li>
                In 2017, over 140 million hours were spent by identity theft
                victims trying to solve their issue. 6
              </li>
              <li>
                There was a victim of identity theft every 2 seconds in 2017. 7
              </li>
              <li>
                Nearly 15 billion dollars were stolen from identity theft
                victims in 2017. 6
              </li>
            </ul>
            <hr />
            The Biggest Lie on the Internet: Ignoring the Privacy Policies and
            Terms of Service Policies of Social Networking Services
            https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2757465 74
            percent of students skipped reading the privacy policy, selecting
            instead a quick "join" option that allowed them to bypass policies.
            Those that did bother to scan the policy spent about a minute doing
            so when, the researchers determined, it would take the average adult
            reader about 30 minutes to read it in its entirety. When it came to
            the Terms of Service, 86 percent of the students spent less than a
            minute reading it.
            <hr />
            1.* Losses are cumulative over five year, 2017 – 2022. James Moar;
            Juniper Research: The Future of Cybercrime & Security: Enterprise
            Threats & Mitigation 2017-2022 (April 25, 2017);
            https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation
            <hr />
            https://www.gartner.com/en/information-technology/security-risk-management-leaders
            <hr />
            * Source - https://cybertechaccord.org/ - Losses are cumulative over
            five year, 2017 – 2022. James Moar; Juniper Research: The Future of
            Cybercrime & Security: Enterprise Threats & Mitigation 2017-2022
            (April 25, 2017);
            https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation
            <hr />
            <h3>
              Users don't have time to read privacy policies, terms of use and
              updated policies nor truly understand them unless they are a
              lawyer.
            </h3>
            <br />
            <h4>
              Who reads privacy policies anymore? Like almost no one.
            </h4>{' '}
            <p>
              {' '}
              Many user agreements &amp; privacy policies can be long to read
              and often are ignored or not understood by users.
            </p>
            <br />
            <h5>Too long; didn't read (abbreviated TL;DR and tl;dr) </h5>
            <p>
              TL;DR - Wikipedia &nbsp;
              <a href="https://en.wikipedia.org/wiki/TL%3BDR">
                https://en.wikipedia.org/wiki/TL%3BDR
              </a>
              <br />
              Wikipedia:Too long; didn't read - Wikipedia&nbsp;
              <a href="https://en.wikipedia.org/wiki/TL%3BDR">
                https://en.wikipedia.org/wiki/Wikipedia:Too_long;_didn%27t_read
              </a>
              <br />
              <br />
              <p>
                <blockquote>
                  Too long; didn't read (abbreviated TL;DR and tl;dr) is a
                  shorthand notation added by an editor indicating that a
                  passage appeared to be too long to invest the time to
                  digest.[3] See the Wikipedia article TL;DR. Wikipedia:Wall of
                  text is kindred. The tl;dr label is often used to point out
                  excessive verbosity or to signify the presence of and location
                  of a short summation in case the reader doesn't want to take
                  the time to read the entire detail (i.e., as a way to find a
                  quick summary for those who think that the article is too long
                  and won't otherwise read it).[4] It can be misused as a tactic
                  to thwart collaborative editing or a stoop to ridicule. This
                  essay examines tl;dr as used in Wikipedia discussions,
                  offering insight into the cause of excessive length,
                  suggestions on how to reduce it, and a reminder to always
                  exercise civility with other editors when paring.
                </blockquote>
              </p>
            </p>
            <p>
              Here are some tweets from influencers on the Internet that
              agree...
            </p>
            <blockquote className="twitter-tweet" data-lang="en">
              <p lang="en" dir="ltr">
                Not reading updated privacy agreements is the new not reading
                original privacy agreements.
              </p>
              &mdash; Dan Primack (@danprimack)
              <a href="https://twitter.com/danprimack/status/1000050767401750531?ref_src=twsrc%5Etfw">
                May 25, 2018
              </a>
            </blockquote>
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charSet="utf-8"
            />
            <p>
              There is no single “place” or repository of user agreements and
              privacy policies for consumers.
            </p>
            <h3>
              Users don't have a privacy and cyber security transparency report
              on every technology they use.
            </h3>
            <p>
              User’s can’t easily understand the confidence of a service on
              numerous sites, apps &amp; systems they use.
            </p>
            <p>
              People have no idea how many times their data has truly been
              breached, stolen, misused. It’s only when something bad happens
              that they know when their data was breached or if the organization
              tells them!
            </p>
            <p>This wastes a huge amount of time for users to figure out</p>
            <p>
              Non-compliant and misleading terms, conditions and privacy
              policies mean that app, website or platform may cause the app to
              lose you as a customer or user.
            </p>
            <p>
              There is a lack of awareness and relevant unbiased consumer
              reports for privacy concerns, privacy history and privacy
              standards analysis, similar to how used car buyers seek Carfax
              reports and customers seek BBB reports, or creditors seek consumer
              credit report histories to qualify for credit.
            </p>
            <h3>People do care about privacy!</h3>
            <p>
              <blockquote>
                "For sure they care. I think common people dont know how to
                protect their data, non tech people like me do care and we feel
                helpless about our privacy bc we don't understand the inner
                workings of the internet and thus have no idea where our
                personal data goes. Having a way to understand how and where our
                data is exposed is a great idea. " -Teacher, Mom
              </blockquote>
            </p>
            <hr />
            <h3>We believe</h3>
            <b> Consumers, end users &amp; customers of Sites/apps</b>
            <ul>
              <li>
                {' '}
                need a better way to understand a new site/apps specific
                policies/user agreements since they are too long and hard to
                understand as well as to keep reviewing when they change.{' '}
              </li>

              <li>
                want to more easily understand the reputation of the company
                they are using Consumers/end users/customers of Free Sites/apps
                also control and monitor how their data is being used instead of
                the other way around.
              </li>
              <li>
                want to increase understanding and confidence of services and
                apps they use online with a new privacy shield on sites and apps
                they use while also making it easy for consumers to save, track
                &amp; analysis of the agreements so that they can gain increased
                engagement, and they would gladly use it and rely on it because
                they would get new benefits such as ____ data sharing
                incentives, data portability solutions etc.
              </li>
            </ul>
            <hr />
            The Biggest Lie on the Internet: Ignoring the Privacy Policies and
            Terms of Service Policies of Social Networking Services
            https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2757465 74
            percent of students skipped reading the privacy policy, selecting
            instead a quick "join" option that allowed them to bypass policies.
            Those that did bother to scan the policy spent about a minute doing
            so when, the researchers determined, it would take the average adult
            reader about 30 minutes to read it in its entirety. When it came to
            the Terms of Service, 86 percent of the students spent less than a
            minute reading it.
            <hr />
            1.* Losses are cumulative over five year, 2017 – 2022. James Moar;
            Juniper Research: The Future of Cybercrime & Security: Enterprise
            Threats & Mitigation 2017-2022 (April 25, 2017);
            https://www.juniperresearch.com/researchstore/innovation-disruption/cybercrime-security/enterprise-threats-mitigation
            <hr />
            <hr />
            <h1>Providers also struggle with privacy</h1>
            <h2>
              Providers are attacked by criminals and nation state actors that
              aren't their fault.
            </h2>
            <p />
            <h2>
              Providers are run by humans which can make human mistakes even
              with the most secure technology.
            </h2>
            <p />
            <h2>
              Providers sometimes engage in activities or define policies that
              need changes.
            </h2>
            <p>
              Non-compliant and misleading terms, conditions and privacy
              policies mean the provider may be fined by regulators and lose the
              user, the revenue and the referrals from the user in addition to a
              damaged reputation in the market and loss of customers to
              competitors if the user is paranoid about their data and privacy.
            </p>
            <h2>Providers need to learn what to comply with then comply.</h2>
            <p>
              Failure for providers to adhere to standard privacy and security
              policies and assurances leads to mistrust of provider brands and
              solutions
            </p>
            {/* 
            <hr />
            <h3>We believe</h3>
            <p>
              {' '}
              <b>Businesses</b> and the owners, executives, employees, staff and
              contractors at these organizations want to establish a new form of
              trust by using a new unbiased policy review service platform and
              shield for their brand and website and policy pages while also
              making it easy for consumers to save and track the agreements so
              that they can gain increased engagement, sign ups, retention as
              well as increase customer trust of their data with their service,
              and they would pay good money for that because drive more growth
              and loyalty, instead of the current fragmented internet where each
              customer must trust blindly without reading policies and user
              agreements the organization of the site/app they use.{' '}
            </p>

            <hr />
            <p>
              <b>Producers Sites/Apps owner companies and organizations</b> are
              willing to register and pay to have their product agreement
              policies analyzed, certified and get a trust shield back to
              because they want to be more trusted by consumers/customers/end
              users and need low cost ways to increase that trust.{' '}
              Organizations serving consumers, can benefit from more user
              trust, engagement, sign ups and retention and make their brand
              look better and their policies easier to understand and buy into
              by consumers leading to less confusion and more transparency. with
              gain a new form of Trust by using our unbiased policy review
              service platform and shield on their registration and
              authentication forms while also making it easy for consumers to
              save and track the agreements so that you can gain increased
              engagement, sign ups, retention as well as increase customer trust
              of their data with their service.
            </p>
            <p>
              We believe that provider organizations want to increase user trust
              by using our unbiased policy review service &amp; platform and a new
              privacy shield on their registration and authentication forms so
              that they can gain increased engagement, sign ups, retention, and
              they would pay money for that because it will help increase
              conversion rate, lift and help them drive more growth and loyalty,
              instead of the current fragmented internet where each customer
              must trust blindly without reading policies and user agreements of
              the organization of the site/app they use.
            </p> */}
            {/* 

          <div className="col-lg-4">
            
            <i className="icon ion-md-happy" />
            <h2></h2>
            <p className=" d-none ">
              Donec sed odio dui. Etiam porta sem malesuada magna mollis
              euismod. Nullam id dolor id nibh ultricies vehicula ut id elit.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
              Praesent commodo cursus magna.
            </p>
            <p className=" d-none ">
              <a className="btn btn-secondary" href="#" role="button">
                View details &raquo;
              </a>
            </p>
          </div>
 
          <div className="col-lg-4">
            <i className="icon ion-md-lock" />
            <h2>Trust</h2>
            <p className=" d-none ">
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula,
              eget lacinia odio sem nec elit. Cras mattis consectetur purus sit
              amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor
              mauris condimentum nibh.
            </p>
            <p className=" d-none ">
              <a className="btn btn-secondary" href="#" role="button">
                View details &raquo;
              </a>
            </p>
          </div>
  
          <div className="col-lg-4">
            <i className="icon ion-md-alert" />
            <h2>Privacy</h2>
            <p className=" d-none ">
              Donec sed odio dui. Cras justo odio, dapibus ac facilisis in,
              egestas eget quam. Vestibulum id ligula porta felis euismod
              semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris
              condimentum nibh, ut fermentum massa justo sit amet risus.
            </p>
            <p className=" d-none ">
              <a className="btn btn-secondary" href="#" role="button">
                View details &raquo;
              </a>
            </p>
          </div>
  */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3>Who reads privacy policies anymore? Very few people...</h3>
            <p>
              Here are some tweets from influencers on the Internet that
              agree...
            </p>
            <blockquote className="twitter-tweet" data-lang="en">
              <p lang="en" dir="ltr">
                Not reading updated privacy agreements is the new not reading
                original privacy agreements.
              </p>
              &mdash; Dan Primack (@danprimack)
              <a href="https://twitter.com/danprimack/status/1000050767401750531?ref_src=twsrc%5Etfw">
                May 25, 2018
              </a>
            </blockquote>
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            />

            
        {/* <!-- Main jumbotron for a primary marketing message or call to action --> */}
        <div className="d-none">
          <Carousel>
            <Carousel.Item>
              {/* <img
            className="d-block w-100"
            src="holder.js/800x400?text=First slide&bg=373940"
            alt="First slide"
          /> */}
              <Carousel.Caption>
                <h3>Your privacy, redefined.</h3>
                <p>
                  No more time wasted on those boring and tricky agreements,
                  unless you want to!
                </p>
                <p>
                  <Button variant="primary">Learn more</Button>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
            className="d-block w-100"
            src="holder.js/800x400?text=Second slide&bg=282c34"
            alt="Third slide"
          /> */}

              <Carousel.Caption>
                <h3>The world's privacy authority &amp; trust platform.</h3>
                <p>
                  We’re simplifying digital privacy for consumers with privacy
                  reports and the world's first Personal User Privacy Policy™.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
            className="d-block w-100"
            src="holder.js/800x400?text=Third slide&bg=20232a"
            alt="Third slide"
          /> */}

              <Carousel.Caption>
                <h3>A simpler way to analyze &amp; track user agreements.</h3>
                <p>
                  We're solving reducing time it takes to decide what to do
                  about those long, boring privacy policies and user agreements.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
            className="d-block w-100"
            src="holder.js/800x400?text=Third slide&bg=20232a"
            alt="Third slide"
          /> */}

              <Carousel.Caption>
                <h3>A better, smarter &amp; safer Internet for all.</h3>
                <p>
                  We’re helping people and organizations gain “confidence” in
                  technology based services &amp; service providers they rely
                  on.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>{' '}
        {/* <div className="jumbotron">
          <div className="container">
            <h1 className="display-32">About DataPolicyTrust</h1>
          </div>
        </div> */}
        {/* <!-- Marketing messaging and featurettes */}
        {/* ================================================== --> */}
        {/* <!-- Wrap the rest of the page in another container to center all the content. --> */}
        <div className="container marketing2">
          <div className="container2">
            {/* <div className="main-container landing">
          <a id="home" className="in-page-link" />

          <section className="header header-2"
          style={{minHeight: '70vh'}}
          > */}
            {/* <div className="background-image-holder">
            <img
              alt="Background Image"
              className="background-image"
              src="/theme/img/hero2.jpg"
            />
          </div> */}
            {/* <div className="container"> */}

            <div className="row">
              <div className="col-md-12">
                <br />
                <br />
                <h2>What is {appName}?</h2>
                {/* <h3>The answer is right here!</h3> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center2  transparent">
                We help people instantly check privacy &amp; cybersecurity
                health -- risk scores, policy analysis, disclosures &amp;
                history -- of any website or product and their organization
                instantly with the world’s first privacy authority platform with
                an AI-powered monitoring service.
                <hr />
                <br />
                <br />
                <h3>Our Vision</h3>
                <h4>To create a better, smarter &amp; safer Internet.</h4>
                <p>
                  DPT makes it simple for people to learn, monitor and keep on
                  top of data privacy &amp; data security concerns in products
                  they use and for companies that collect data without their
                  consent (Equifax, etc.)
                </p>
                <hr />
                <h3>Why Now?</h3>
                <p>
                  {' '}
                  When you use technology and online services, you’re trusting
                  them with your information. We understand this is a big
                  responsibility and we work hard to help inform you on how to
                  protect your information and put you in control. We've
                  invented the world's first Global Privacy Authority Platform
                  along with the World's first Global User Privacy Policy -
                  meant to help people and users of all Internet connected
                  systems understand what information technology services
                  collect, why they collect it, and how you can update, manage,
                  export, and delete your information.{' '}
                </p>{' '}
                {/* <h3>Learn more about {appName}</h3> */}
                <hr />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 ">
                <h2>Concept of DataPolicyTrust.com</h2>
                <h3>The Problem</h3>{' '}
                <p>
                  Everyone knows that the Internet is huge, there are many
                  online sites, apps, products and services and many
                  organizations are now required to take great measures to
                  protect a consumer and customer's privacy and data rights.
                  There's a lot of unsavory or irresponsible Web sites &amp;
                  services out there that will try to get you to opt into
                  clauses on complex user agreements and fine print or get you
                  to opt in to things you didn't realize until later on. So, how
                  can you find out if a Web site is a privacy risk before you
                  visit it?
                </p>
                <p>
                  {' '}
                  Our world is growing of people, devices &amp; apps and also
                  creating a data “deluge”, information silos, vendor lock in,
                  privacy concerns, security issues and a world of fragmentation
                  with systems that are often not designed with user data
                  privacy in mind out of the box. As data breaches occur, people
                  depend on organizations to inform them of a breach which
                  doesn’t always happen. Recent regulatory changes such as EU’s
                  GDPR, has fines against violating providers that can go up to
                  €20 million (or 4 percent of annual global turnover),
                  whichever is highest. Privacy policies &amp; user agreements
                  are often not even read or understood by consumers. When
                  changes occur, users often just skip it and give consent.
                  Consumers need a better way to understand, review &amp; keep
                  track of new &amp; updated user agreements.
                  {/* There’s
          no easy way to bring together one’s data in one place with privacy
          controls &amp; incentives in sharing anonymized data service
          provider’s may desire. */}
                </p>
                {/* <h3>Problem</h3>{' '} */}
                <p>
                  {' '}
                  People and organizations are growing concerned on:
                  <br />
                  <br />
                  risks to their personal privacy, wallet &amp; time on paid and
                  free products, sites, apps &amp; services what aspects of
                  policies &amp; terms are of importance when using a technology
                  how their data is used in products (services, apps, sites,
                  SaaS, software, portals) how trustworthy &amp; transparent are
                  providers with privacy and security policies, practices &amp;
                  standards
                </p>
                <h3>The Solution</h3>{' '}
                <p>
                  {appName} is a free service from{' '}
                  <a href="/company">UVision Corporation</a>. Our servers
                  analyze Web sites to see how they will affect you and your
                  computer. Then, using the browser extension installed on your
                  PC in Google Chrome, we let you know how safe a particular Web
                  site might be when you use it. If you need to find out more
                  about how a Web site might behave, we provide more detailed
                  information right here on {appName}.
                </p>
                <p>
                  DataPolicyTrust.com - is the world’s first “Privacy Authority”
                  platform &amp; end user computing system with interfaces
                  across realities, devices &amp; data. Our platform will help
                  create a new “People Centered Internet”. We foster more trust
                  between end users and organizations that collect and use user
                  data (sites, apps, social network, Internet Service, SaaS
                  etc.). We provide a platform to enable companies to map user
                  agreements (TOS, PP etc.) to regulations as well as privacy
                  ‘pledge’ and a risk score for consumers to review as well as a
                  dashboard to track user agreements &amp; feel safer with
                  services they use.
                </p>
                <p>
                  {appName} is a platform, mobile app, browser add-on and web
                  site. {appName} is a data privacy authority service, providing
                  analysis, reviews and other data about whether websites,
                  services, products, SaaS software companies and cloud
                  platforms respect user privacy, are secure, and other
                  indicators of trust.
                </p>
                {/*             
        <h3>Solution</h3>{' '} */}
                <p>
                  {' '}
                  Considering all the data companies and smart devices collect,
                  it's time for a trusted authority to monitor the safety and
                  security of users' data. DataPolicyTrust™ will save time,
                  money &amp; reduce loss, risk, waste, fraud, theft &amp;
                  confusion for all people. DPT fosters more trust between
                  consumers & providers to build a better, smarter &amp; safer
                  Internet. DPT will help trustworthy providers get more users
                  if they invest in great privacy &amp; security standards and
                  culture.
                </p>
                {/* <h3>Company Overview</h3>{' '} */}
                <p>
                  DataPolicyTrust.com™ (DPT) is the world’s first “Privacy
                  Authority” platform, global user privacy portal with dashboard
                  &amp; an AI-powered monitoring service for sites, apps,
                  services & products &amp; their organizations. DPT provides
                  freemium services via its website, search engine, browser
                  extension, mobile app and more as a trusted solution,
                  delivering privacy reports for consumers and the industry. Our
                  company fosters a people-centered approach to empower
                  individual consumers and concerned communities alike to be
                  more equipped to handle an era of unprecedented data breaches
                  and a tsunami of conflicting reports involving what is, and
                  isn't, done with sensitive data. We help users with: risks to
                  their personal privacy, wallet &amp; time on paid and free
                  products, sites, apps &amp; services what aspects of policies
                  &amp; terms are of importance when using a technology how
                  their data is used in products (services, apps, sites, SaaS,
                  software, portals) how trustworthy &amp; transparent are
                  providers with privacy and security policies, practices &amp;
                  standards
                </p>
                <hr />
                <p>
                  DPT also has an API for our apps for users as well as for the
                  industry partners who need additional DPT data to protect
                  their networks. DPT platform continually analyzes the Internet
                  and many other data sources for new data on providers and
                  their products and provides a search platform, browser
                  extensions, mobile apps, for users starting with simplified
                  policy and privacy reports on the organizations whose policies
                  they consented to and also didn’t consent to. Premium features
                  will be tested for monitoring changes and alerts for these
                  organizations collecting data on those users. Providers of
                  websites, products, services with policies and data collection
                  efforts can opt in and subscribe annually to disclose
                  additional info for data privacy transparency pledge and for
                  more trust. -----API and more)
                </p>
                <h3>Our Initial Focus at DataPolicyTrust.com</h3>
                <p>
                  DataPolicyTrust&trade; will improve the transparency of data
                  policies &amp; practices (data privacy, cybersecurity, etc.)
                  of all products (apps, sites, SaaS, software, marketplaces,
                  platforms etc.) &amp; their providers.{' '}
                </p>
                <p>
                  <ProblemSolutionBenefit />
                </p>
                <h3>Benefits of DataPolicyTrust.com</h3>
                <div className="container marketing">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="text-black font-weight-normal" />
                    </div>
                  </div>

                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          We're stepping up and basically saying to businesses
                          and marketers don’t be "irresponsible" - and if you
                          are, user's will take charge.{' '}
                        </p>
                        <p>
                          Specifically, some of the user's preferences and
                          privacy controls that we're enabling for consumers
                          are:
                          <ul>
                            <li>Don’t send spam</li>
                            <li>Don’t bait and switch</li>
                            <li>Get consent to collect data</li>
                            <li>
                              Be transparent on how they use the collected data
                            </li>
                            <li>Respect people's wishes when they want out</li>
                            <li>
                              Allow people to easily get their user data out
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h3>The Advantages of DataPolicyTrust</h3>{' '}
                <p>
                  {/* <h4>TRUST. INTELLIGENCE. PRIVACY.</h4>  */}
                  Unlike past alternatives, we’re creating the world’s first
                  platform to streamline &amp; ‘trust’ between organizations and
                  consumers on the Internet.
                </p>
                <h4>Intelligent Unified Data Privacy Standard</h4>{' '}
                <p>
                  A new common standard for easy understanding for end users,
                  businesses, providers, regulators, experts, attorneys &amp;
                  auditors.
                </p>
                <h4>Simple Privacy Verification Reporting</h4>{' '}
                <p>An unbiased friendly report of privacy policies.</p>
                <h4>Privacy Policy Trust Shield</h4>{' '}
                <p>
                  Easy to understand ‘seal’ for end users to simplify the
                  experience.
                </p>
                <h4>User Agreement Tracking &amp; Reporting</h4>
                <p>Centralized tracking, alerts, reports for consumers.</p>
                <h4>AI &amp; Machine Learning Analysis</h4>
                <p>
                  Monitor, control &amp; analysis of privacy policies and user
                  agreements.
                </p>
                <hr />
                {/* <hr/> */}
                <h2>
                  Why We Need DPT Now and moving forward?
                  {/* Needs the idea will satisfy (problems, new opportunities, savings)  */}
                </h2>
                <p>
                  Our society is increasing its dependence on a growing number
                  of technologies creating privacy concerns &amp; security
                  issues leading to a world of fragmentation with systems often
                  not designed with privacy in mind. As breaches &amp; crisis
                  continue, people depend on organizations to be transparent
                  &amp; inform them of a breach which doesn’t always happen. Few
                  technology providers are excellent in their privacy &amp;
                  cybersecurity practices. Consumers &amp; the Internet industry
                  at large need a better way to continually monitor ‘trust’ of a
                  service or app.
                  <ul>
                    <li>
                      <li>
                        99% of consumers skip reading policies and user
                        agreements that can take over 10 minutes to read
                      </li>
                      <li>
                        Many users do not know what is important and what is not
                        in a policy or user agreement
                      </li>
                      <li>
                        Users do not hire a lawyer or pay someone to review
                        policies and user agreements unless it’s a business
                      </li>
                      <li>
                        Users do not read ‘updated’ policies &amp; they often
                        don’t know what or why the agreements changed
                      </li>
                      <li>
                        Users don’t have a central dashboard to watch services,
                        their changing policies &amp; their practices
                      </li>
                      <li>
                        Privacy policies &amp; agreements are not a good way to
                        analyze a service or provider for privacy &amp;security
                      </li>
                      <li>
                        In 2019, FTC says people don’t read policies but they
                        are useful as they force providers to implement
                      </li>
                      processes but still are often not in compliance or
                      sometimes doing unethical actions behind the scenes.
                    </li>
                    <li>
                      With recent 2019 FB/FTC settlement, FTC &amp; FB are
                      accused of doing secret settlement terms.
                    </li>
                  </ul>
                  DataPolicyTrustTM will save time, money &amp; reduce loss,
                  risk, waste, fraud, theft &amp; confusion for everyone
                  including people and reputable services that have great
                  privacy and security standards. DataPolicyTrust will also help
                  reputable and trustworthy providers build more trust &amp;get
                  more users.
                </p>
                <hr />
                <h3>Purpose</h3>
                <p>
                  DataPolicyTrust was created by leaders of the technology
                  industry, because they have been frustrated by the quality,
                  service, and high costs and stress that people, customers,
                  organizations, investors, employees and their families have
                  experienced in the U.S. technology system and markets globally
                  that use technology. Technology is used globally by over 4.18
                  billion people as a tool to work, live, play, create, invent
                  and solve challenges into the future. We believe that we can
                  do better for people, and in taking this step to form this new
                  organization, we have committed to being a part of the
                  solution.
                </p>{' '}
                <p>
                  <ul>
                    <li>
                      We know that this work will take time, and we'll need the
                      help of others, but we will tackle problems step-by-step
                      and make sure that people remain our top priority. These
                      are our guiding principles:
                    </li>
                    <li>
                      We will be an advocate for the people and an ally to
                      anyone — individuals, families, businesses, professionals,
                      industry leaders, innovators, policymakers, and others —
                      who makes customer care and technology solutions better.
                    </li>
                    <li>
                      We will create new solutions and work to change
                      technologies, contracts, policy, and whatever else is in
                      the way of better data privacy, data security and services
                      around people's lives and work.
                    </li>
                    <li>
                      We will be relentless. We will insure our work has high
                      impact and is sustainable. And we are committed to doing
                      this work for the long-term.
                    </li>
                    <li>
                      We believe all people deserve quality privacy and security
                      technology solutions and services that are intelligent,
                      useful, time saving but also private, safe, trusted,
                      affordable and accessible.
                    </li>
                    <li>
                      And we believe that the technology industry itself which
                      is made of amazing scientists, technologists, engineers,
                      designers, creatives, employees, contractors and
                      entrepreneurs can continue to work to make the world a
                      better place, together, for the rest of the 7 Billion+
                      other homo sapiens on our planet.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Tabs --> */}
        {/* <section id="tabs">
<div className="container">
  <!-- <h6 className="section-title h1">Tabs</h6> -->
  <div className="row">
      <div className="col-xs-12 ">
          <nav>
              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Home</a>
                  <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</a>
                  <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</a>
                  <a className="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">About</a>
              </div>
          </nav>
          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
          </div>
      
      </div>
  </div>
</div>
</section> */}
        {/* <!-- ./Tabs --> */}
        <div className="container">
          {/* 
<h4>Write a review of a Web site</h4>
<p>Share your stories and experiences—good or bad—about any Web site that you feel strongly about! Help others avoid
  the same pitfalls that you've gone through on some sites. Plus, let us know which sites are extraordinary and
  exceptional.</p> */}

          {/* <hr /> */}
          {/* <center> */}

          {/* <h3>Coming Soon</h3>
        <h4>Dispute Resolution</h4>
        <p>
          Centralized dispute tools for consumers, service providers and
          regulator review.
        </p> */}
          {/* </center> */}
          {/* 
<h3>Revenue Model</h3> Tiered pricing starting at $100/year for small service providers (small sites or apps) with scaled up tiered pricing for large service providers (i.e. Facebook 2.2B users @ $100K or up). Consumers are freemium but will be offered premium subscriptions for additional benefits. Sponsorship revenue through a consortium of alliance partners.  We may also consider generating additional revenues from affiliate commissions with partners such as for infrastructure, compliance, risk, governance, cybertech, security, assessments, etc. 
  
<h3>The Team</h3> 
Founder: Omar Uddin, Entrepreneur. Technologist. Engineer. Inventor. Previously Founder/CEO UVision.

<h3>Advisory Board</h3> 
Rob La Gesse, Former VP of Social Strategy at Rackspace. Startup mentor, investor &amp;advisor.
Ghufran Ahmed - VP at Oracle, Formerly VP at Ebay, Microsoft, Sun Microsystems.  
Steve Crutchley - Governance, Risk, Compliance and Data Privacy Expert, Formerly CISO of Mattell and UMG
Todd Friedman - */}
          {/* <hr/><h3>Elevator pitch </h3>
        <p>I
        help organizations and consumers solve Gain more brand trust, loyalty
        and help their users understand their policies better., and yield a More
        of user trust, engagement, sign ups and retention and make their brand
        look better and their policies easier to understand and buy into by
        consumers leading to less confusion and more transparency.. Positioning
        Statement For organizations and consumers at Consumer Who gain increased
        engagement, sign ups, retention as well as increase customer trust of
        their data with their service, DataPolicyTrust™ is a platform That gain
        increased engagement, sign ups, retention as well as increase customer
        trust of their data with their service. Unlike the current fragmented
        internet where each customer must trust blindly without reading policies
        and user agreements the organization of the site/app they use
        DataPolicyTrust™ .</p> */}
          {/* <hr />
        <h3>A Word from our Founder and CEO</h3>
        <p>
          "As an engineer, inventor, advisor and serial entrepreneur, I've
          devoted my career to caring for my stakeholders, customers and users
          and working to make the entire technology world and the 'grid' better.{' '}
        </p>
        <p>
          I believe all people deserve quality technology solutions and services
          that are intelligent, useful, time saving but also private, safe,
          trusted, affordable and accessible.
        </p>
        <p>
          On that note, I decided recently, that I would pause some things I was
          considering doing to spend the next couple of years to help solve some
          key problems by starting to put an end to the lack of transparency
          over the risks in the Internet to help change things for the better.
          I've built and constructed a variety of inventions from a few days of
          inspiration while advising global audiences on privacy and policy
          issues. One of them is a sort of 'T.R.O.N.' SENTINAL AI SYSTEM with
          algorithms and adaptive capabilities thanks to AI+human combination
          that tracks the risks of every data collecting technology, product and
          service in the world. "
         
        </p> */}
        </div>
        <div className="container marketing">
          {/* <!-- Tabs --> */}
          {/* <section id="tabs">
<div className="container">
  <!-- <h6 className="section-title h1">Tabs</h6> -->
  <div className="row">
      <div className="col-xs-12 ">
          <nav>
              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Home</a>
                  <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</a>
                  <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</a>
                  <a className="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">About</a>
              </div>
          </nav>
          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
          </div>
      
      </div>
  </div>
</div>
</section> */}
          {/* <!-- ./Tabs --> */}
        </div>
        {/* <!-- /.container --> */}
        <div className="container">
          <hr />

          <h3>Why Trust Us</h3>

          <h4>
            We will never share your private data or activities with third
            parties!
          </h4>

          <p>
            Our mission is to help build a better, smarter and safer future for
            all and we're starting with the Internet which over 4.16 billion
            humans have come to rely on. We're not in it to share your personal
            information or use it inappropriately, quite the opposite in fact.
            We hope to help transform our tech enabled world for the better with
            your help. We aim to make life easier for consumers and customers in
            determining how their favorite Internet websites, services,
            platforms, social networks, and SaaS products are using their data
            and if they are complying with standards, regulations as well as
            your own policies.{' '}
          </p>

          <hr />
          <br />
          <br />
          <People />
          <hr />
          <p>
            <center>
              {' '}
              <h3>Opportunity</h3>{' '}
              <h4>1B+ websites (2018) - 2B+ websites (MARCH 2019)</h4>
              <h4>10+ Million Apps</h4>
              <h4>18.2M developers</h4>
              <h4>Millions of Providers</h4>
              <h4>4.16B+ users</h4>
              <h4>2.4B+ mobile devices</h4>
              <h4>7+ Billion IoT devices</h4>
              <h4>260M Online US Consumers</h4>
              <h4>30M US SMBs US with 58.9M Employees</h4>
            </center>
          </p>
          <p>
            <BelowTheFold />
          </p>
          <p />
        </div>
        {/* <hr/> */}
        {/* <Timeline lineColor={'#ddd'}>
        <TimelineItem
          key="001"
          dateText="11/2010 – Present"
          style={{ color: '#e86971' }}
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="002"
          dateText="04/2009 – 11/2010"
          dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
          bodyContainerStyle={{
            background: '#ddd',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
          }}
        >
          <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
          <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="003"
          dateComponent={
            <div
              style={{
                display: 'block',
                float: 'left',
                padding: '10px',
                background: 'rgb(150, 150, 150)',
                color: '#fff',
              }}
            >
              11/2008 – 04/2009
            </div>
          }
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="004"
          dateText="08/2008 – 11/2008"
          dateInnerStyle={{ background: '#76bb7f' }}
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
      </Timeline> */}
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {' '}
              <h3>Links</h3>
              {/* <References /> */}
              {/* <b>References</b> */}
              <ul>
                <li>
                  <p>
                    WOT Services - Wikipedia -
                    <a href="https://en.wikipedia.org/wiki/WOT_Services">
                      https://en.wikipedia.org/wiki/WOT_Services
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Norton Safe Web - Wikipedia -
                    <a href="https://en.wikipedia.org/wiki/Norton_Safe_Web">
                      https://en.wikipedia.org/wiki/Norton_Safe_Web
                    </a>
                  </p>
                </li>

                <li>
                  <p>
                    McAfee SiteAdvisor - Wikipedia -
                    <a href="https://en.wikipedia.org/wiki/McAfee_SiteAdvisor">
                      https://en.wikipedia.org/wiki/McAfee_SiteAdvisor
                    </a>
                  </p>
                </li>

                <li>
                  <p>
                    SafeSearch - Wikipedia -
                    <a href="https://en.wikipedia.org/wiki/SafeSearch">
                      https://en.wikipedia.org/wiki/SafeSearch
                    </a>
                  </p>
                </li>

                <li>
                  <p>
                    "Facebook Security — Keeping You Safe from Scams and Spam".
                    12 May 2011. Retrieved 31 May 2014. Keeping You Safe from
                    Scams and Spam | Facebook
                    <a href="https://www.facebook.com/notes/facebook-security/keeping-you-safe-from-scams-and-spam/10150174826745766/">
                      https://www.facebook.com/notes/facebook-security/keeping-you-safe-from-scams-and-spam/10150174826745766/
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>{' '}
        </div>
        
        {/* <div className="background-image-holder">
            <img
              alt="Background Image"
              className="background-image"
              src="/theme/img/hero2.jpg"
            />
          </div> */}
            <hr />  
            <b>References</b>
            <ol>
              <li>
                <p>
                  Kelley, Micheal B. "Skype Could Be Helping The Feds Spy On US
                  Citizens By Handing Over Chats." Business Insider, 26 July
                  2012,{' '}
                  <a
                    href="http://www.businessinsider.com/skype-accused-of-helping-government-spy-on-people-2012-7"
                    rel="nofollow"
                  >
                    www.businessinsider.com/skype-accused-of-helping-government-spy-on-people-2012-7
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Granville, Kevin. "Facebook and Cambridge Analytica: What You
                  Need to Know As Fallout Widens."{' '}
                  <em>
                    The New York Times - Breaking News, World News &amp;
                    Multimedia
                  </em>
                  , 19 Mar. 2018,{' '}
                  <a
                    href="http://www.nytimes.com/2018/03/19/technology/facebook-cambridge-analytica-explained.html"
                    rel="nofollow"
                  >
                    www.nytimes.com/2018/03/19/technology/facebook-cambridge-analytica-explained.html
                  </a>
                </p>
              </li>
              <li>
                <p>
                  Bank of America to Pay $16.65 Billion in Historic Justice
                  Department Settlement for Financial Fraud Leading Up to and
                  During the Financial Crisis."{' '}
                  <em>U.S. Department of Justice</em>,{' '}
                  <a
                    href="http://www.justice.gov/opa/pr/bank-america-pay-1665-billion-historic-justice-department-settlement-financial-fraud-leading"
                    rel="nofollow"
                  >
                    www.justice.gov/opa/pr/bank-america-pay-1665-billion-historic-justice-department-settlement-financial-fraud-leading
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  "26 Amazing Skype Statistics and Facts (November 2017)."{' '}
                  <em>DMR</em>, 2 Feb. 2016,
                  <a
                    rel="nofollow"
                    href="https://expandedramblings.com/index.php/skype-statistics/"
                  >
                    https://expandedramblings.com/index.php/skype-statistics/
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  "CLOUD Act." <em>Wikipedia, the Free Encyclopedia</em>,
                  Wikimedia Foundation, Inc, 3 Apr. 2018,
                  <a
                    rel="nofollow"
                    href="https://en.wikipedia.org/wiki/CLOUD_Act"
                  >
                    https://en.wikipedia.org/wiki/CLOUD_Act
                  </a>
                  . Accessed 4 Apr. 2018.
                </p>
              </li>{' '}
              <li>
                {' '}
                Based on an online survey of 540 U.S. adults who experienced ID
                theft in 2017, conducted for Symantec by the Harris Poll,
                January 2018.{' '}
                <a
                  rel="nofollow"
                  href="https://www.lifelockbusinesssolutions.com/industries/lifelock-breach-response-services/"
                >
                  https://www.lifelockbusinesssolutions.com/industries/lifelock-breach-response-services/
                </a>
                <br />
              </li>
              <li>
                Based on an online survey of 5,389 U.S. adults conducted for
                Symantec by The Harris Poll, January 2018{' '}
                <a
                  rel="nofollow"
                  href="https://www.lifelockbusinesssolutions.com/industries/lifelock-breach-response-services/"
                >
                  https://www.lifelockbusinesssolutions.com/industries/lifelock-breach-response-services/
                </a>
              </li>
            </ol>
          </div>{' '}
        </div>
      </div>

      {/* <Annotation/> */}
    {/* </Layout> */}
  </div>
);

// export Why;


const TimelineVertical = () => <div />;

class References extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [
        {
          // key: 'wot',
          title: 'WOT Services',
          source: 'Wikipedia',
          url: 'https://en.wikipedia.org/wiki/WOT_Services',
        },
        {
          // key: 'norton-safeweb',

          title: 'Norton Safe Web',
          source: 'Wikipedia',
          url: 'https://en.wikipedia.org/wiki/Norton_Safe_Web',
        },
        {
          // key: 'mcafee-siteadvisor',
          title: 'McAfee SiteAdvisor',
          source: 'Wikipedia',
          url: 'https://en.wikipedia.org/wiki/McAfee_SiteAdvisor',
        },
        {
          // key: 'safesearch',
          title: 'SafeSearch ',
          source: 'Wikipedia',
          url: 'https://en.wikipedia.org/wiki/SafeSearch',
        },
        {
          // key: 'wot',
          title: 'WOT Services',
          source: 'Wikipedia',
          url: 'https://en.wikipedia.org/wiki/WOT_Services',
        },
        {
          // key: 'wot',
          title:
            '"Facebook Security — Keeping You Safe from Scams and Spam" - 12 May 2011. Retrieved 31 May 2014. Keeping You Safe from Scams and Spam | Facebook',
          source: 'Wikipedia',
          url:
            'https://www.facebook.com/notes/facebook-security/keeping-you-safe-from-scams-and-spam/10150174826745766/',
        },
      ],
    };
  }

  render() {
    return (
      <div className="App">
        <Table2 data={this.state.tableData} />
      </div>
    );
  }
}

class Table2 extends React.Component {
  constructor(props) {
    super(props);
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
  }

  getKeys = function() {
    return Object.keys(this.props.data[0]);
  };

  getHeader = function() {
    var keys = this.getKeys();
    return keys.map((key, index) => {
      // if (index=='key')
      // continue;
      return <th key={key}>{key.toUpperCase()}</th>;
    });
  };

  getRowsData = function() {
    var items = this.props.data;
    var keys = this.getKeys();
    return items.map((row, index) => {
      // if (index=='key')
      // continue;
      return (
        <tr key={index}>
          <RenderRow key={index} data={row} keys={keys} />
        </tr>
      );
    });
  };

  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>{this.getHeader()}</tr>
          </thead>
          <tbody>{this.getRowsData()}</tbody>
        </table>
      </div>
    );
  }
}

const RenderRow = props => {
  return props.keys.map((key, index) => {
    return (
      <td data-key={key} key={props.data[key]}>
        <div
          dangerouslySetInnerHTML={{
            __html: props.data[key],
          }}
        />
      </td>
    );
  });
};

class ProblemSolutionBenefit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      related: [
        {
          '#': 1,
          Problem:
            'It takes >30 mins for a student or adult human to properly analyze and comprehend user agreements and policies. ',
          Solution:
            'DataPolicyTrust.com website and search engine with DPT Service Profile - similar to credit bureaus but instead for data privacy - a trusted consumer report for privacy, policy  and user agreement analysis tools with parameters with simple questions, scores',
          Benefit: `Review any site or product's privacy policies in 10 seconds.`,
        },
        {
          '#': 2,
          Problem:
            'Analyzing user agreement policy risks and info from other review sites does not tell a person how great a company’s standards are at security and privacy or trust and safety. ',
          Solution:
            'Digital Trust Score similar to FICO score - similar to how FICO is a constantly changing number to indicate trust of a person with money, Digital Trust Score is based on data privacy practices, policies and historical performance data with  compliance.',
          Benefit: `Review any site or product's historical track record in 10 seconds.`,
        },
        {
          '#': 3,
          Problem:
            'There is no central site or browser extension etc or API and search engine along with historical report on the practices of any product or service and their organization and a central place to store agreements between user and org.',
          Solution:
            'DataPolicyTrust.com platform with trusted ledger, search engine, API, dashboard and browser extension',
          Benefit: `Store user agreements to track the data privacy practices and history of all providers.`,
        },
        {
          '#': 4,
          Problem:
            'There is no way to continually monitor the trust of data brokers and services that I consent to.',
          Solution: 'AI Powered Alerts and Monitoring of Services for All',
          Benefit: `Monitor and track the data privacy practices and history of all providers continually.`,
        },
      ],
    };
  }

  render() {
    return (
      <div className="problemsolutionbenefit">
        <br />
        <Table2 data={this.state.related} />
      </div>
    );
  }
}
const BelowTheFold = () => (
  <div className="text-center">
    <br />
    <hr />
    <br />
    <h2>The Internet Needs You</h2>
    <br />
    <h4>Help improve the Internet</h4>
    {process.env.NODE_ENV == 'development' ? (
      <>
        <p>
          Suggest a <Link to="/suggest/">service, app or technology</Link>.
        </p>
      </>
    ) : (
      <></>
    )}

    <p>
      Register as a <Link to="/providers/">provider organization</Link>.
    </p>
    <p>
      Register your <Link to="/organizations/">teams &amp; staff</Link>.
    </p>
    <br />
    {/* <hr /> */}
    {/* <br /> */}
    <br />
    {/* <TwitterTweetEmbed
  tweetId={'933354946111705097'}
/> */}
    {/* <TweetEmbed id='1157283275729039360'  */}
    {/* // options={{theme: 'dark' }} */}

    {/* /> */}
  </div>
);



class Affix extends React.Component {
  render() {
    return (
      <div className="affix-example">
        <AutoAffix viewportOffsetTop={15} container={this}>
          <div className="panel panel-default">
            <div className="panel-body">I am an affixed element</div>
          </div>
        </AutoAffix>
      </div>
    );
  }
}

const People = () => (
  <div className="container text-center">
    <h2>{JSONData.title}</h2>
    <div className=" row  justify-content-center text-center">
      {/* <h2>{JSONData.title}</h2> */}
      <Carousel
        data-interval="15000"
        style={{ color: '#ddd', padding: '25px' }}
      >
        {JSONData.content.map((data, index) => {
          return (
            <Carousel.Item style={{ height: `auto !important` }}>
              <Carousel.Caption style={{ position: `auto !important` }}>
                <div key={`2content_item_${index}`}>
                  <span
                    className="home-testimonial"
                    style={{ fontWeight: '200' }}
                    dangerouslySetInnerHTML={{
                      __html:
                        ' <span className="quote">' +
                        data.item +
                        '</span><br/><br/><p><span className="quote-author">' +
                        data.author +
                        '</span></p>',
                    }}
                  />
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  </div>
);
